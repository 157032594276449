<template>
  <div class="novel-put">
    <!-- <div
      class="novel-put-menu margin-top-bottom-twenty"
      v-show="$route.fullPath !== '/novelPut/promotionPage'"
    >
      <div class="put-manager put" @click="goToPage(2)">
        <span :class="{ 'is-active': isHighlight === 2 }">投放管理</span>
      </div>
      <div class="resource-manager put">
        <p>投放素材管理</p>
        <p  @click="goToPage(1)"><span :class="{ 'is-active': isHighlight === 1 }">服务商</span></p>
        <p  @click="goToPage(3)">
          <span :class="{ 'is-active': isHighlight === 3 }">推广页</span>
        </p>
        <p  @click="goToPage(4)">
          <span :class="{ 'is-active': isHighlight === 4 }">投放素材</span>
        </p>
      </div>
    </div> -->
    <router-view style="flex-grow:1" :isActive.sync="isActive"></router-view>
  </div>
</template>
<script>
export default {

  name: 'novelPut',
  data () {
    return {
      isActive: 3,
      isHighlight: 1
    }
  },
  mounted () {
    this.setHighlight()
  },

  watch: {
    $route: {
      handler (newV) {
        if (newV.name === 'Client') {
          this.isHighlight = 1
        }
        if (newV.name === 'NovelProjectManager') {
          this.isHighlight = 2
        }
        if (newV.name === 'DeliveryMaterialManage' && this.isActive === 3) {
          this.isHighlight = 3
        }
        if (newV.name === 'DeliveryMaterialManage' && this.isActive === 4) {
          this.isHighlight = 4
        }
        if (newV.name === 'AddNovelPut' || newV.name === 'EchoNovelPut') {
          this.isHighlight = 4
        }
      },
      immediate: true
    },
    isActive: {
      handler (newV) {
        if (newV === 3) {
          this.isHighlight = 3
        }
        if (newV === 4) {
          this.isHighlight = 4
        }
      }
    }
  },
  methods: {
    goToPage (index) {
      if (index === 1) {
        this.$router.push({
          name: 'Client'
        })
      }
      if (index === 2) {
        this.$router.push({
          name: 'NovelProjectManager'
        })
      }
      if (index === 3) {
        this.isActive = 3
        this.$router.push({
          // name: 'DeliveryMaterialManage'
          name: 'PageManage'
        })
      }
      if (index === 4) {
        this.isActive = 4
        this.$router.push({
          // name: 'DeliveryMaterialManage'
          name: 'MaterialManage'
        })
      }
      // if (this.$route.name !== 'DeliveryMaterialManage') {
      //   this.$router.push({
      //     name: 'DeliveryMaterialManage'
      //   })
      //   // this.$forceUpdate()
      // }

      this.isActive = index
    },
    goToProjectManage () {
      this.$router.push({
        name: 'NovelProjectManager'
      })
    },
    setHighlight () {
      if (this.$route.name === 'Client') {
        this.isHighlight = 1
      }
      if (this.$route.name === 'NovelProjectManager') {
        this.isHighlight = 2
      }
      if (
        this.$route.name === 'DeliveryMaterialManage' &&
        this.isActive === 3
      ) {
        this.isHighlight = 3
      }
      if (
        this.$route.name === 'DeliveryMaterialManage' &&
        this.isActive === 4
      ) {
        this.isHighlight = 4
      }
    },
    putAd () {
      this.$router.push({
        name: 'Client'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-menu {
  width: 200px !important;
}
/deep/ .el-menu .is-active {
  background-color: #e8f4f5 !important;
}
.is-active {
  border-bottom: 2px solid #00bf8a !important;
  padding-bottom: 4px;
  color: #00bf8a;
}
.novel-put {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .novel-put-menu {
    background: #fff;
    // width: 200px !important;
    height:842px ;
    padding-top:8px;
    box-sizing: border-box;
    padding:10px;

    .put {
      padding: 15px 20px;
      cursor: pointer;
      font-weight: bold;
      font-size:15px;
    }
    .resource-manager {
      display: flex;
      flex-direction: column;
      p {
        display: inline-block;
        height: 20px;
        border-bottom: 2px solid #fff;
      }
      p:nth-of-type(1) {
        font-weight: bold;
      }
      p:not(:nth-of-type(1)) {
        width:65px;
        margin:16px 13px 0 ;
        font-weight: normal;
      }
    }
  }
}
</style>
